
import { computed, defineComponent, onMounted, ref } from "vue";
import Entities from "@/components/base/common/Entities.vue";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { LoadFilterObjects } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import RecentCards from "@/components/base/common/RecentCards.vue";
import ClaimCard from "@/views/claim/ClaimCard.vue";

export default defineComponent({
  name: "MyClaims",
  components: {ClaimCard,  RecentCards, Entities},
  setup: function () {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('My Claims',
        [
          {link: false, router: '', text: 'My Claims'}
        ]
      )
    })
    const filterObject = ref({myClaim: true})
    const page = computed(() => store.state.ClaimModule.listPageClaim)
    return {
      page,
      ...LoadFilterObjects(Actions.LOAD_LIST_CLAIMS, filterObject.value, ['client', 'insured', 'caze', 'examiner'])
    }

  }
})
